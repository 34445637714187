<template>
  <v-skeleton-loader v-bind="attrs" type="article"></v-skeleton-loader>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      height: 130,
      class: "mb-1",
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style></style>
