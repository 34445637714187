<template>
  <div class="p-0 m-0">
    <Sidebar />

    <div>
      <StoreImage :store_detail="detailStore" />
    </div>
    <div class="ps-2">
      <Username />
    </div>
    <div style="margin-bottom: 7em">
      <HeaderNavigasi :link="links" />

      <div v-if="isLoading" class="text-center">
        <div v-for="i in 7" :key="i">
          <SkeletonProduct />
        </div>
      </div>

      <div v-else>
        <!-- NOT FOUND -->
        <div v-if="listItemOrderByStore.length == 0">
          <v-container class="pb-1">
            <div class="d-flex flex-row align-center justify-center mt-12">
              <h3 style="color: grey">
                <!-- {{ $t("message.no-have-product-history-store") }} -->
                {{ $t("message.no-purchase-history") }}
              </h3>
            </div>
          </v-container>
        </div>

        <!-- Card Order History  -->
        <v-card
          class="odd_even"
          v-else
          v-for="(order, idx) in listItemOrderByStore"
          :key="idx"
          flat
          @click="getLink(order)"
        >
          <ListProduct :data="order" />
        </v-card>

        <div>
          <CategoryFooter />
        </div>
      </div>
    </div>
    <Cashier />
  </div>
</template>

<script>
import CategoryFooter from "../../components/CategoryFooter.vue";
import ListProduct from "../../components/ListProduct.vue";
import LoadingPage from "../../components/LoadingPage.vue";
import HeaderNavigasi from "../../components/HeaderNavigasi.vue";
import User from "../../components/User.vue";
import SkeletonProduct from "../../components/skeleton/SkeletonProduct.vue";
import Cashier from "../../components/Cashier.vue";
import StoreImage from "../../components/StoreImage.vue";
import Sidebar from "../../components/Sidebar.vue";
import { mdiMagnify, mdiHeartOutline, mdiCart, mdiStore } from "@mdi/js";
export default {
  name: "ShopPurchaseHistory",
  data() {
    return {
      idStore: "",
      isLoading: false,
    };
  },
  components: {
    Sidebar,
    Cashier,
    StoreImage,
    Username: User,
    HeaderNavigasi,
    LoadingPage,
    ListProduct,
    CategoryFooter,
    SkeletonProduct,
  },
  methods: {
    async dispatchStore() {
      this.idStore = this.$router.history.current.params.id;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch("shop_module/fetchStore", {
        id: id,
      });
      return data;
    },
    async itemOrderByStore() {
      this.isLoading = true;
      let id = this.$router.history.current.params.id;
      let data = await this.$store.dispatch(
        "order_module/itemOrderByStore",
        id
      );
      this.isLoading = false;
      return data;
    },
    getLink(order) {
      switch (order.delivery_status) {
        case "locker_key":
          this.$router.push(`/order-history/${order.order_id}/${order.locker_key}`);
          break;
        default:
          this.$router.push(`/store/${this.idStore}/purchase-history/detail/${order.order_id}`);
          break;
      }
    },
  },
  computed: {
    detailStore() {
      return this.$store.state.shop_module.store;
    },
    listItemOrderByStore() {
      return this.$store.state.order_module.itemOrderByStore;
    },
    links() {
      return [
        {
          text: this.$t("message.route-search"),
          route: `/store/${this.idStore}/category`,
          icon: mdiMagnify,
        },
        {
          text: this.$t("message.route-favorite"),
          route: `/store/${this.idStore}/wishlist`,
          icon: mdiHeartOutline,
        },
        {
          text: this.$t("message.route-history"),
          route: `/store/${this.idStore}/purchase-history`,
          icon: mdiCart,
        },
        {
          text: this.$t("message.route-store"),
          route: "/store",
          icon: mdiStore,
        },
      ];
    },
  },
  created() {
    this.dispatchStore();
    this.itemOrderByStore();
  },
};
</script>

<style></style>
