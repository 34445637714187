<template>
  <div>
    <div class="d-flex align-center">
      <Warning :openDialog="dialog" @handle-dialog="handleDialog" />
    </div>
    <v-list-item three-line class="ps-2 pe-2">
      <v-list-item-avatar tile size="80" color="grey">
        <v-img :src="data.image_url" @click="(e) => getModal(e, data.slug)"></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-row align="center" justify="space-between">
          <v-col cols="5" class="pe-0">
            <div class="item_name-class">
              {{ data.item_name }}
            </div>
          </v-col>
          <v-col cols="1">
            <div class="">
              <v-icon @click="addFavorite(data)" dark :color="isFavorite()">
                mdi-heart
              </v-icon>
            </div>
          </v-col>
          <v-col cols="4" class="ps-0" style="text-align: right">
            <div v-if="data.tax_category != 2">
              <div class="text_align text_color">
                {{ moneyFormat(data.price) }}
              </div>
              <div class="text_align" style="font-size: 10px">
                {{ $t("message.with-tax") }}
                {{ parseFloat(data.price) + parseFloat(data.tax) }}
              </div>
            </div>
            <div v-else>
              <div class="text_align text_color">
                {{ $t("message.with-tax") }}
                {{ parseFloat(data.price) + parseFloat(data.tax) }}
              </div>
              <div class="text_align" style="font-size: 10px"></div>
            </div>
            <div class="text_align" style="font-size: 10px">
              {{ store.store_name }}
            </div>
          </v-col>
        </v-row>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import moneyFormat from "../helpers/moneyFormat";
import SkeletonProduct from "@/components/skeleton/SkeletonProduct";
import Warning from "../components/Warning.vue";

export default {
  name: "Product",
  components: {
    Warning,
  },
  data() {
    return {
      notFound: require("../assets/image-not-found.png"),
      message: {},
      dialog: false,
      itemId: "",
      storeId: "",
      modal_dialog: false,
    };
  },
  props: {
    data: Object,
    loading: Boolean,
    store: Object,
  },
  computed: {},
  methods: {
    getModal(e, data) {
      if(e == 'ConfirmProduct') {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      } else {
        this.$emit("data-slug", data);
        this.$emit("dialog", true);
      }
    },
    handleDialog(val) {
      if (val.selected == "yes") {
        this.message.title = this.$t("message.title-add-carts");
        this.$store.dispatch("carts_module/addCarts", {
          itemId: this.itemId,
          storeId: this.storeId,
          message: {
            title: this.message.title,
          },
        });
      }
      this.dialog = false;
    },
    moneyFormat(money) {
      return moneyFormat(money);
    },
    isFavorite() {
      if (this.data.has_favourites === 1) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
    addFavorite: function (data) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("wishlist-item-click", {
          event_category: "Enhanced Ecommerce",
          event_label: data.item_name,
          value: data.price_item,
        });
        // let itemId = data.item_id;
        // let slug = data.slug;
        // let payload = { item_id: itemId, slug: slug };
        let payload = { item_id: data.item_id, page: "order_history" };
        this.$store.dispatch("itemFavorite_module/addItemFavorite", payload);
      }
    },
    addCart(e, data) {
      this.itemId = data.item_id;
      this.storeId = this.store.store_id;
      e.stopPropagation();
      let cookies = $cookies.get("isLogin");
      if (cookies === 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        if (data.is_alcohol_item == 1) {
          this.dialog = true;
        } else {
          this.message.title = this.$t("message.title-add-carts");
          this.$store.dispatch("carts_module/addCarts", {
            itemId: data.id,
            storeId: this.store.store_id,
            message: {
              title: this.message.title,
            },
          });
        }
      }
    },
  },
};
</script>

<style>
.text_color {
  color: #ff0090;
  font-weight: 800;
}
.text_align {
  text-align: right;
  padding-top: 3px;
}
.odd_even:nth-child(even) {
  background-color: #e1dddd;
}
.odd_even:nth-child(odd) {
  background-color: white;
}
.item_name-class {
  text-overflow: ellipsis;
  /* width: 70px; */
  /* height: auto; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-height: 20px;
  overflow: hidden;
  -webkit-box-orient: vertical;
}
.grid-card-order {
  /* display: flex;
  justify-content: space-between; */
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(2rem, auto)); */
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
}
.class-price {
  width: 60px;
  padding: 0 5px 0 0;
}
.class-icon-fav {
  padding-top: 25px;
}
</style>
