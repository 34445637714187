<template>
  <v-container style="padding: 0px;" class="p-0 m-0">
    <v-row align="center" justify="center">
      <v-dialog

        id="dialog-warning"
        class="dialog-warning"
        v-model="openDialog"
        persistent
        max-width="350"
      >
        <v-card>
          <div class="mx-3 py-5">
            <v-img :lazy-src="warning" max-height="150" :src="warning"></v-img>
          </div>
          <v-card-text class="text-center black--text" style="font-size: 18px"
            >20歳以上ですか？</v-card-text
          >
          <v-card-actions class="text-center d-flex justify-center">
            <v-btn
              class="white--text"
              depressed
              color="#ff0090"
              @click.prevent="handleDialog('yes')"
            >
              はい
            </v-btn>
            <v-btn
              class="black--text"
              depressed
              color="#BDBDBD"
              @click.prevent="handleDialog('no')"
            >
              いいえ
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Warning",
  data() {
    return {
      dialog: false,
      warning: require("../assets/stop20.png"),
      dialogWarning: false,
    };
  },
  props: {
    openDialog: Boolean,
  },
  mounted() {},
  methods: {
    handleDialog(choose) {
      this.$emit("handle-dialog", {
        dialog: this.openDialog,
        selected: choose,
      });
    },
  },
  computed: {},
};
</script>

<style>
.v-dialog {
/* position: absolute;
  top: 30% !important;
  box-shadow: none !important; */
}
</style>
