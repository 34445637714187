<template>
  <v-progress-circular :size="70" :width="7" indeterminate color="indigo">
    <v-img
      :lazy-src="logoLoading"
      max-height="40"
      max-width="40"
      :src="logoLoading"
    ></v-img>
  </v-progress-circular>
</template>

<script>
import logo from "../assets/favicon-32x32.png";

export default {
  name: "LoadingPage",
  data(){
    return {
      logoLoading: logo,
    }
  }
};
</script>

<style></style>
