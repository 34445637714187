<template>
  <div>
    <v-card
      class="mx-auto justify-center text-center"
      style="position: relative"
    >
      <router-link :to="`/store/${idStore}`" style="text-decoration: none">
        <v-img
          tile
          style="border-radius: 0px; opacity: 0.8; margin-top: -2em"
          class="white--text text-center d-flex align-center justify-center"
          height="200px"
          :src="store_detail.image_url"
        >
          <v-card-title
            class="text-center d-flex justify-center"
            style="z-index: 3; margin-top: 2.5em"
            >{{ store_detail.store_name }}</v-card-title
          >
          <div
            style="
              position: absolute;
              background-color: black;
              opacity: 0.5;
              top: 12px;
              width: 100%;
              height: 300px;
              z-index: -1;
            "
          ></div>
        </v-img>
      </router-link>
      <v-icon
        @click="addFavorite(store_detail)"
        dark
        :color="isFavorite()"
        style="position: absolute; bottom: 4px; left: 4px; padding: 6px;border-radius: 3rem;background-color: #ffffff;"
      >
        mdi-heart
      </v-icon>
    </v-card>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "StoreImage",
  data() {
    return {
      idStore: "",
    };
  },
  props: {
    store_detail: Object,
  },
  methods: {
    storeId() {
      this.idStore = this.$router.history.current.params.id;
    },
    addFavorite: function (store) {
      let cookies = $cookies.get("isLogin");
      if (cookies == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: this.$t("message.title-err-login-first"),
        });
      } else {
        this.$gtag.event("store-favorite-click", {
          event_category: "Favorite Store",
          event_label: store.store_name,
          value: store.store_name,
        });
        let payload = store.id;
        this.$store.dispatch("shop_module/addStoreFavorite", payload);
      }
    },
    isFavorite() {
      if (this.store_detail.has_favourites === 1) {
        return "#ff0090";
      } else {
        return "grey";
      }
    },
  },
  beforeMount() {
    window.scrollTo(0, 0);
  },
  created() {
    this.storeId();
  },
};
</script>

<style scoped>
.class-tulisan {
  position: absolute;
  left: 25%;
  top: 50%;
  text-align: center;
}
.img {
  opacity: 0.5;
}
.detail-img {
  opacity: 0.9;
  margin-top: 25px;
  width: 100%;
  height: 125px;
  margin-bottom: 10px;
  position: relative;
  background-color: black;
}
</style>
